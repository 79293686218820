<template>
    <div class="item_box">
        <img class="important_img" :src="img_important" v-if="item.is_important==1" alt="">
        <div  class="left">
            <img :src="item.img_url==''||!item.img_url?default_img:item.img_url" alt="">
        </div>
        <div class="right">
            <div class="tit"> 
                <h1  @click="toDetail" :title="item.title | textFormat ">{{ item.title | textFormat }}</h1>
            </div>
            <div class="date">发布时间： {{ item.created_time | secondFormat('LL') }}</div>
            <div class="msg">
                <div >{{ item.content | fullTextFormat(100) }}</div>
                <el-link class="link"  @click="toDetail" :underline="false" type="primary">查看更多<i class="el-icon-arrow-right el-icon--right"></i></el-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:Object,
        default:function(){
            return {}
        },
        default_img:String,
        img_important:String
    },
    methods:{
        // fullTextFormat,
        toDetail(){
            let routeUrl = this.$router.resolve({
                path: "/detail",
                query:{
                    id:this.item.id
                }
                
            });
            window.open(routeUrl.href, "_blank");
        }
    }
}
</script>
<style lang="less" scoped>
    .item_box{
        width: 588px;
        height: 191px;
        margin-bottom:24px ;
        background: #fff;
        padding: 20px;
        position: relative;
        display: flex;
        .important_img{
            position: absolute;
            top: 4px;
            right: -3px;
        }
        .left{
            cursor: pointer;
            width: 200px;
            height: 151px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .right{
            padding-left: 16px;
            width:332px;
            .tit{
                display: flex;
                align-items: center;
                cursor: pointer;
                img{
                    padding-right:5px;
                }
                h1{
                    width: 332px;
                    overflow: hidden;
                    height: 28px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 27px;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }
            .date {
              color: #909399;
              font-size: 12px;
              margin-top: 4px;
            }
            .msg{
                padding-top:10px;
                position: relative;
                div{
                    height: 100px;
                    word-break:break-all;
                }
                .link{
                    position: absolute;
                    right: 4px;
                    bottom: 4px;
                }
            }
        }
    }
</style>